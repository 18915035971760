import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const ItalicIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={"0 -960 960 960"}
    height="24px"
    width="24px"
    fill="currentColor"
  >
    <path d="M200-200v-100h160l120-360H320v-100h400v100H580L460-300h140v100H200Z" />
  </SvgIcon>
);

export default ItalicIcon;

import React from "react";
import styled from "styled-components";

import { SecondaryButton } from "~/components/common/buttons";
import Dialog from "~/components/generic/Dialog";

interface GuideDialogProps {
  show: boolean;
  onHide: () => void;
}

const GuideDialog = ({ show, onHide }: GuideDialogProps) => {
  return (
    <Dialog
      widthVariant="large"
      isOpen={show}
      onRequestClose={onHide}
      header="Markdown Guide"
      actions={<SecondaryButton onClick={onHide}>Close</SecondaryButton>}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>Code</th>
            <th>Or</th>
            <th>Linux/Windows</th>
            <th>Mac OS</th>
            <th>... to Get</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>*Italic*</td>
            <td>_Italic_</td>
            <td>Ctrl+I</td>
            <td>Command+I</td>
            <td>
              <em>Italic</em>
            </td>
          </tr>
          <tr>
            <td>**Bold**</td>
            <td>__Bold__</td>
            <td>Ctrl+B</td>
            <td>Command+B</td>
            <td>
              <strong>Bold</strong>
            </td>
          </tr>
          <tr>
            <td>{"<ins>Underline</ins>"}</td>
            <td>&mdash;</td>
            <td>&mdash;</td>
            <td>&mdash;</td>
            <td>
              <ins>Underline</ins>
            </td>
          </tr>
          <tr>
            <td>~~Strikethrough~~</td>
            <td>&mdash;</td>
            <td>Shift+S</td>
            <td>Option+S</td>
            <td>
              <del>Strikethrough</del>
            </td>
          </tr>
          <tr>
            <td># Heading 1</td>
            <td>
              Heading 1<br /> =========
            </td>
            <td>Ctrl+Alt+1</td>
            <td>Command+Option+1</td>
            <td>
              <h1>Heading 1</h1>
            </td>
          </tr>
          <tr>
            <td>## Heading 2</td>
            <td>
              Heading 2<br /> -----------
            </td>
            <td>Ctrl+Alt+2</td>
            <td>Command+Option+2</td>
            <td>
              <h2>Heading 2</h2>
            </td>
          </tr>
          <tr>
            <td>[Link](http://a.com)</td>
            <td>
              [Link][1]
              <br /> &#8285;
              <br /> [1]: http://b.org
            </td>
            <td>Ctrl+L</td>
            <td>Command+L</td>
            <td>
              <a href="http://commonmark.org/">Link</a>
            </td>
          </tr>
          <tr>
            <td>![Image](http://url/a.png)</td>
            <td>
              ![Image][1]
              <br /> &#8285;
              <br /> [1]: http://url/b.jpg
            </td>
            <td>Ctrl+Shift+I</td>
            <td>Command+Option+I</td>
            <td>
              <img
                src="/static/plugins/images/commonmark.png"
                width="36"
                height="36"
                alt="Markdown"
              />
            </td>
          </tr>
          <tr>
            <td>
              A paragraph.
              <br />
              <br /> A paragraph after 1 blank line.
            </td>
            <td>&mdash;</td>
            <td>&mdash;</td>
            <td>&mdash;</td>
            <td>
              <p>A paragraph.</p>
              <p>A paragraph after 1 blank line.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                * List
                <br /> * List
                <br /> * List
              </p>
            </td>
            <td>
              <p>
                {" "}
                - List
                <br /> - List
                <br /> - List
                <br />
              </p>
            </td>
            <td>Ctrl+U</td>
            <td>Command+U</td>
            <td>
              <ul>
                <li>List</li>
                <li>List</li>
                <li>List</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                {" "}
                1. One
                <br /> 2. Two
                <br /> 3. Three
              </p>
            </td>
            <td>
              <p>
                {" "}
                1) One
                <br /> 2) Two
                <br /> 3) Three
              </p>
            </td>
            <td>Ctrl+Shift+O</td>
            <td>Command+Option+O</td>
            <td>
              <ol>
                <li>One</li>
                <li>Two</li>
                <li>Three</li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              Horizontal Rule
              <br />
              <br /> ---
            </td>
            <td>
              Horizontal Rule
              <br />
              <br /> ***********
            </td>
            <td>Ctrl+H</td>
            <td>Command+H</td>
            <td>
              Horizontal Rule
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              "Projects Table"
              <br />
              <br />
              <pre>&#x3C;ProjectsTable /&#x3E;</pre>
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              Projects Table
              <br />
              <br />
              Will generate a projects table based on projects data from the
              current portfolio.
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              Map
              <br />
              <br />
              <pre>&#x3C;Map /&#x3E;</pre>
            </td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>
              Map
              <br />
              <br />
              Will create a map with markers showing locations of all the
              projects from the current portfolio (uses the Postcode from
              projects data for determining the location).
              <br />
              <br />
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </Dialog>
  );
};

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;

  th {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }

  td {
    padding: 10px;
    border: 1px solid #ddd;
  }
`;

export default GuideDialog;

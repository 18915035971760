import { gql } from "@apollo/client";
import React from "react";
import { Button, Image } from "react-bootstrap";
import styled from "styled-components";

import { Toolbar } from "~/components/common/toolbars";
import Dialog from "~/components/generic/Dialog";
import LoadingSpinner from "~/components/generic/LoadingSpinner";
import {
  useCreateSubpageMediaMutation,
  useSubpageMediaQuery,
} from "~/components/Overview/SubpageMediaModal.generated";

export const SUBPAGE_MEDIA_QUERY = gql`
  query SubpageMedia($subjectId: ID!, $subjectType: String!) {
    subpageMedia(subjectId: $subjectId, subjectType: $subjectType) {
      id
      url
    }
  }
`;

export const CREATE_SUBPAGE_MEDIA_MUTATION = gql`
  mutation CreateSubpageMedia(
    $subpageId: ID!
    $subjectId: ID!
    $subjectType: String!
    $file: Upload!
  ) {
    createSubpageMedia(
      subpageId: $subpageId
      subjectId: $subjectId
      subjectType: $subjectType
      image: $file
    ) {
      subpage {
        id
        url
      }
    }
  }
`;

interface SubpageMediaModalProps {
  show: boolean;
  onHide: () => void;
  onSelect: (id: string) => void;
  subpageId: string;
  subjectId: string;
  subjectType: string;
}

const SubpageMediaModal = ({
  show,
  onHide,
  onSelect,
  subpageId,
  subjectId,
  subjectType,
}: SubpageMediaModalProps) => {
  const [createSubpageMedia, { loading: subpageMediaLoading }] =
    useCreateSubpageMediaMutation();
  const { data, loading, error } = useSubpageMediaQuery({
    variables: { subjectId, subjectType },
  });

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (file) {
        try {
          await createSubpageMedia({
            variables: {
              subpageId: subpageId,
              subjectId: subjectId,
              subjectType: subjectType,
              file: file,
            },
            refetchQueries: ["SubpageMedia"],
          });
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    }
  };

  return (
    <Dialog
      isOpen={show}
      onRequestClose={onHide}
      header="Import or Select Media"
      actions={
        <Toolbar>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Toolbar>
      }
    >
      {subpageMediaLoading && (
        <SpinnerOverlay>
          <LoadingSpinner color="dark" />
        </SpinnerOverlay>
      )}
      <div>
        <b>
          Import image from computer:{" "}
          <input type="file" onChange={handleFileUpload} />
        </b>
      </div>
      <div className="mt-4">
        <b>Select from imported media:</b>
        <div className="media-gallery">
          {data?.subpageMedia?.map((media) => (
            <Image
              key={media.id}
              src={media.url}
              thumbnail
              onClick={() => onSelect(media.id)}
            />
          ))}
        </div>
        {loading && <p>Loading...</p>}
        {error && <p>Error loading media</p>}
      </div>
    </Dialog>
  );
};

export default SubpageMediaModal;

const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../api/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SubpageMediaQueryVariables = Types.Exact<{
  subjectId: Types.Scalars['ID'];
  subjectType: Types.Scalars['String'];
}>;


export type SubpageMediaQuery = (
  { readonly __typename: 'Query' }
  & { readonly subpageMedia?: Types.Maybe<ReadonlyArray<(
    { readonly __typename: 'GenericSubpageMedia' }
    & Pick<Types.GenericSubpageMedia, 'id' | 'url'>
  )>> }
);

export type CreateSubpageMediaMutationVariables = Types.Exact<{
  subpageId: Types.Scalars['ID'];
  subjectId: Types.Scalars['ID'];
  subjectType: Types.Scalars['String'];
  file: Types.Scalars['Upload'];
}>;


export type CreateSubpageMediaMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createSubpageMedia?: Types.Maybe<(
    { readonly __typename: 'CreateSubpageMediaMutation' }
    & { readonly subpage?: Types.Maybe<(
      { readonly __typename: 'GenericSubpageMedia' }
      & Pick<Types.GenericSubpageMedia, 'id' | 'url'>
    )> }
  )> }
);


export const SubpageMediaDocument = gql`
    query SubpageMedia($subjectId: ID!, $subjectType: String!) {
  subpageMedia(subjectId: $subjectId, subjectType: $subjectType) {
    id
    url
  }
}
    `;

/**
 * __useSubpageMediaQuery__
 *
 * To run a query within a React component, call `useSubpageMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubpageMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubpageMediaQuery({
 *   variables: {
 *      subjectId: // value for 'subjectId'
 *      subjectType: // value for 'subjectType'
 *   },
 * });
 */
export function useSubpageMediaQuery(baseOptions: Apollo.QueryHookOptions<SubpageMediaQuery, SubpageMediaQueryVariables>) {
        return Apollo.useQuery<SubpageMediaQuery, SubpageMediaQueryVariables>(SubpageMediaDocument, baseOptions);
      }
export function useSubpageMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubpageMediaQuery, SubpageMediaQueryVariables>) {
          return Apollo.useLazyQuery<SubpageMediaQuery, SubpageMediaQueryVariables>(SubpageMediaDocument, baseOptions);
        }
export type SubpageMediaQueryHookResult = ReturnType<typeof useSubpageMediaQuery>;
export type SubpageMediaLazyQueryHookResult = ReturnType<typeof useSubpageMediaLazyQuery>;
export type SubpageMediaQueryResult = Apollo.QueryResult<SubpageMediaQuery, SubpageMediaQueryVariables>;
export const CreateSubpageMediaDocument = gql`
    mutation CreateSubpageMedia($subpageId: ID!, $subjectId: ID!, $subjectType: String!, $file: Upload!) {
  createSubpageMedia(
    subpageId: $subpageId
    subjectId: $subjectId
    subjectType: $subjectType
    image: $file
  ) {
    subpage {
      id
      url
    }
  }
}
    `;
export type CreateSubpageMediaMutationFn = Apollo.MutationFunction<CreateSubpageMediaMutation, CreateSubpageMediaMutationVariables>;

/**
 * __useCreateSubpageMediaMutation__
 *
 * To run a mutation, you first call `useCreateSubpageMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubpageMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubpageMediaMutation, { data, loading, error }] = useCreateSubpageMediaMutation({
 *   variables: {
 *      subpageId: // value for 'subpageId'
 *      subjectId: // value for 'subjectId'
 *      subjectType: // value for 'subjectType'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateSubpageMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubpageMediaMutation, CreateSubpageMediaMutationVariables>) {
        return Apollo.useMutation<CreateSubpageMediaMutation, CreateSubpageMediaMutationVariables>(CreateSubpageMediaDocument, baseOptions);
      }
export type CreateSubpageMediaMutationHookResult = ReturnType<typeof useCreateSubpageMediaMutation>;
export type CreateSubpageMediaMutationResult = Apollo.MutationResult<CreateSubpageMediaMutation>;
export type CreateSubpageMediaMutationOptions = Apollo.BaseMutationOptions<CreateSubpageMediaMutation, CreateSubpageMediaMutationVariables>;
import useFilterDropdown, {
  stateReducerExamples,
} from "components/FilterDropdown/useFilterDropdown";
import * as PropTypes from "prop-types";
import React from "react";
import { gettext } from "utils/text";

import {
  DropdownMenu,
  DropdownMenuAlign,
  DropdownMenuTheme,
} from "../menus/DropdownMenu";
import { filterItemsByKeyword } from "./utils";

const SubpagesFilterDropdown = ({
  selectedPage,
  items,
  title,
  onSelected,
  disabled,
}) => {
  const {
    getItemProps,
    getInputProps,
    keyword,
    selected,
    isOpen,
    getToggleProps,
  } = useFilterDropdown({
    selected: [selectedPage],
    onSelected,
    stateReducer: (state, nextState) =>
      stateReducerExamples.closeAfterSelected(
        state,
        stateReducerExamples.allowExactlyOneSelection(state, nextState)
      ),
  });

  const selectedItem = items.find((item) => selected.includes(item.key));
  const iconClass = (isSelected) => (isSelected ? "icon--ok-blue" : "");

  return (
    <DropdownMenu
      withClose
      theme={DropdownMenuTheme.BLUE}
      menuAlign={DropdownMenuAlign.LEFT}
      className="filter-dropdown dropup"
      btnText={selectedItem ? selectedItem.value : gettext("Overview")}
      isOpen={isOpen}
      disabled={disabled}
      toggleOpen={getToggleProps().onClick}
    >
      <div className="dropdown-header">
        <div className="filter-dropdown__title-container">
          <h4 className="filter-dropdown__title">{title}</h4>
        </div>

        <div className="d-flex align-items-center">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="icon icon--search" />
              </span>
            </div>
            <input
              {...getInputProps({
                className: "form-control form-control-sm",
                placeholder: gettext("Filter..."),
                disabled: disabled,
              })}
            />
          </div>
        </div>
      </div>
      <div className="dropdown-body-up">
        <ul className="list-group list-group-flush">
          {filterItemsByKeyword(items, keyword).map((item, index) => (
            <li
              {...getItemProps({
                key: item.key,
                index,
                item,
                className: `dropdown-item icon ${iconClass(
                  selected.includes(item.key)
                )}`,
              })}
            >
              {item.value}
            </li>
          ))}
        </ul>
      </div>
    </DropdownMenu>
  );
};

SubpagesFilterDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  selectedPage: PropTypes.string,
  disabled: PropTypes.bool,
};

SubpagesFilterDropdown.defaultProps = {
  selectedPage: null,
  disabled: false,
};

export default SubpagesFilterDropdown;

import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const UnderlineIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={"0 -960 960 960"}
    height="24px"
    width="24px"
    fill="currentColor"
  >
    <path d="M200-120v-80h560v80H200Zm280-160q-101 0-157-63t-56-167v-330h103v336q0 56 28 91t82 35q54 0 82-35t28-91v-336h103v330q0 104-56 167t-157 63Z" />
  </SvgIcon>
);

export default UnderlineIcon;
